// tslint:disable:quotemark import-spacing indent object-literal-key-quotes no-namespace eofline
import { FaIconLibrary } from '@fortawesome/angular-fontawesome/icon-library';
// import { FontAwesomeModule  } from '@fortawesome/angular-fontawesome/fontawesome.module';
// import { Library, library } from '@fortawesome/fontawesome-svg-core';

import {
	faAnchor,
	faAngleUp,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faArrowLeft,
	faArrowRight,
	faArrowRotateLeft,
	faAsterisk,
	faAt,
	faBan,
	faBars,
	faBell,
	faBolt,
	faCalendarAlt,
	faCalendarDay,
	faCaretLeft,
	faCaretRight,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCircleExclamation,
	faCircleInfo,
	faCircleNotch,
	faCircleQuestion,
	faCircleXmark,
	faCloudDownloadAlt,
	faCloudUploadAlt,
	faCode,
	faCodeBranch,
	faComment,
	faCommentSlash,
	faCommentDollar,
	faCommentDots,
	faCopy,
	faDeaf,
	faDog,
	faDollarSign,
	faDownload,
	faEdit,
	faEllipsis,
	faEnvelope,
	//faExternalLinkAlt,
	// faEye,
	// faEyeSlash,
	faFile,
	faFileAudio,
	faFileCsv,
	faFileExcel,
	faFileImage,
	faFileImport,
	faFileInvoiceDollar,
	faFilePdf,
	faFileUpload,
	faFileWord,
	faFilter,
	faFlagCheckered,
	faFloppyDisk,
	// faGear,
	faGauge,
	faHandHoldingHeart,
	faHandHoldingMedical,
	faHome,
	faKey,
	faKeyboard,
	faLaptopCode,
	faLifeRing,
	faLink,
	faListUl,
	faLock,
	faMagnifyingGlass,
	faMinus,
	faMobileAlt,
	faNewspaper,
	faPaperclip,
	// faPeopleArrows,
	faPersonWalkingWithCane,
	faPhone,
	faPlay,
	faPlus,
	faPlusCircle,
	//faSave,
	// faSearch,
	faSignOutAlt,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faSquare,
	faStepBackward,
	faStepForward,
	faSyncAlt,
	faTasks,
	faTh,
	// faThumbsDown,
	// faThumbsUp,
	faThumbtack,
	// faToggleOff,
	// faToggleOn,
	//faTimes,
	//faTimesCircle,
	faTrafficLight,
	faTrashAlt,
	faTriangleExclamation,
	//faUndo,
	faUnlink,
	faUnlock,
	faUpload,
	faUpRightFromSquare,
	faUser,
	faUserCircle,
	// faUserCog,
	faUserNinja,
	faUsers,
	faXmark
} from '@fortawesome/free-solid-svg-icons';

import { faTwitter, faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import {
	faEye,
	faEyeSlash,
	faGear,
	faGripVertical,
	faPeopleArrows,
	faToggleOn,
	faToggleOff,
	faUserCog
} from "@fortawesome/pro-duotone-svg-icons";

export function initIconLibrary(library: FaIconLibrary): void {
	const icons = [
		faAnchor,
		faAngleUp,
		faAngleDown,
		faAngleLeft,
		faAngleRight,
		faArrowLeft,
		faArrowRight,
		faArrowRotateLeft,
		faAsterisk,
		faAt,
		faBan,
		faBell,
		faBars,
		faBolt,
		faCalendarAlt,
		faCalendarDay,
		faCaretLeft,
		faCaretRight,
		faCheck,
		faCheckCircle,
		faCheckSquare,
		faChevronLeft,
		faChevronRight,
		faCircle,
		faCircleExclamation,
		faCircleInfo,
		faCircleNotch,
		faCircleQuestion,
		faCircleXmark,
		faCloudDownloadAlt,
		faCloudUploadAlt,
		faCode,
		faCodeBranch,
		faComment,
		faCommentSlash,
		faCommentDollar,
		faCommentDots,
		faCopy,
		faDeaf,
		faDog,
		faDollarSign,
		faDownload,
		faEdit,
		faEllipsis,
		faEnvelope,
		//faExternalLinkAlt,
		faFile,
		faFileAudio,
		faFileCsv,
		faFileExcel,
		faFileImage,
		faFileImport,
		faFileInvoiceDollar,
		faFilePdf,
		faFileUpload,
		faFileWord,
		faFilter,
		faFlagCheckered,
		faFloppyDisk,
		// faGear,
		faGauge,
		faHandHoldingHeart,
		faHandHoldingMedical,
		faHome,
		faKey,
		faKeyboard,
		faLaptopCode,
		faLifeRing,
		faLink,
		faListUl,
		faLock,
		faMagnifyingGlass,
		faMinus,
		faMobileAlt,
		faNewspaper,
		faPaperclip,
		faPeopleArrows,
		faPersonWalkingWithCane,
		faPhone,
		faPlay,
		faPlus,
		faPlusCircle,
		//faSave,
		// faSearch,
		faSignOutAlt,
		faSort,
		faSortDown,
		faSortUp,
		faSpinner,
		faSquare,
		faStepBackward,
		faStepForward,
		faSyncAlt,
		faTasks,
		faTh,
		// faThumbsDown,
		// faThumbsUp,
		faThumbtack,
		//faTimes,
		//faTimesCircle,
		faTrafficLight,
		faTrashAlt,
		faTriangleExclamation,
		//faUndo,
		faUnlink,
		faUnlock,
		faUpload,
		faUpRightFromSquare,
		faUser,
		faUserCircle,
		faUserCog,
		faUserNinja,
		faUsers,
		faXmark
	];

	const brands = [
		faTwitter,
		faFacebook,
		faInstagram,
		faLinkedin
	];

	const duotoneIcons = [
		faEye,
		faEyeSlash,
		faGear,
		faGripVertical,
		faToggleOff,
		faToggleOn
	];

	library.addIcons(...icons);
	library.addIcons(...duotoneIcons);
	library.addIcons(...brands);
}
