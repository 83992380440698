import * as libphonenumber from 'google-libphonenumber';
import {assert} from '@classes/errors';
import {DateTime} from 'luxon';

/**
 * The custom primary log level for the app. Set to error for production builds.
 */
const level: 'log'|'info'|'error'|'safeEval'|'palString'|'mapUtils'|'resultSet'|'reset'|'table'|'toggle'|'fullToggle'|'isComplete'
      |'trafficLights'|'trafficLightsAmount' = 'error';

/**
 * Container for a list of functions that help with typescript and/or Angular.js.
 */
export const GeneralUtils = {

  /**
   * Logs to the console, only if the log level is `'log'`
   */
  log: ['log'].includes(level) ? console.log : () => {},
  info: ['log', 'info'].includes(level) ? console.log : () => {},
  error: ['log', 'info', 'error', 'safeEval', 'mapUtils', 'palString', 'resultSet', 'reset', 'table', 'fullToggle'].includes(level) ?
    console.error : () => {},
  safeEval: ['log', 'safeEval', 'mapUtils'].includes(level) ? console.log : () => {},
  palString: ['log', 'palString', 'mapUtils'].includes(level) ? console.log : () => {},
  resultSet: ['log', 'resultSet'].includes(level) ? console.log : () => {},
  reset: ['log', 'reset'].includes(level) ? console.log : () => {},
  toggle: ['log', 'toggle'].includes(level) ? console.log : () => {},
  fullToggle: ['log', 'toggle', 'fullToggle'].includes(level) ? console.log : () => {},
  isComplete: ['log', 'isComplete'].includes(level) ? console.log : () => {},

  /**
   * Used to compare and validate traffic light representations of data within the app
   */
  trafficLights: [
    'log',
    'trafficLights'
  ].includes(level) ? console.log : () => {},

  /**
   * Used to compare and validate traffic light representations of data within the app, but only amount
   */
  trafficLightsAmount: [
    'log',
    'trafficLights',
    'trafficLightsAmount'
  ].includes(level) ? console.log : () => {},

  custom: (arr) => arr.includes(level) ? (console.log) : (() => {}),

  /**
   * Binds a list of functions to its owner so that it always interprets `this` as its owner
   *
   * @param input The owner of the function
   * @param args The functions to bind
   */
  bind: (input: any, ...args: Function[]) => {
    for (let arg of args) {
      const name: string = arg.name;
      input[name] = arg.bind(input);
    }
  },

  /**
   * Binds all functions of an object to it.
   *
   * <b><u>Usage</u></b>: Use this in the constructor of every single object.
   *
   * @param input The owner of the functions
   *
   * @see bind
   */
  bindAll: (input: any) => {
    const args: Function[] = Object.keys(input).filter(key => typeof (input)[key] === 'function').map(v => input[v]);
    GeneralUtils.bind(input, ...args);
  }

};

// tslint:disable-next-line:no-namespace
export namespace NumberUtils {

  export function parse(src: string|number): number {
    const n = Number(src);
    return Number.isNaN(n) ? undefined : n;
  }

  /**
   * Rounds a number to 2dp, suitable for comparing currency values and avoiding rounding errors.
   */
  export function currency(src: string|number): number {
    const n = NumberUtils.parse(src);
    if (n === undefined) {
      return undefined;
    }

    return Math.round(n * 100) / 100;
  }
}

export class StringUtils {

  public static readonly ellipsis = '…';

  public static sameString(a: string, b: string, allowNullAndUndefined: boolean = true): boolean {
    return !a && !b && allowNullAndUndefined ? true : a === b;
  }

  public static trim(value: string|null|undefined): string {
    return value?.trim() ?? value;
  }

  public static trimmedLength(value: string|null|undefined): number {
    return (value ?? '').trim().length;
  }

  public static truncateString(text: string, maxLength: number = 100): string {
    const content = text || '';
    return content.length > maxLength ? content.slice(0, maxLength).split(' ').slice(0, -1).join(' ') + StringUtils.ellipsis : content;
  }

  public static optionalString(value: string|undefined|null, defaultValue: string = ''): string {
    return value ?? defaultValue;
  }

  /**
   * Returns a string formatted as a 2dp number with commas and a leading $ sign. Suitable for error messages, etc
   */
  public static currency(value: string|number|undefined|null): string {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'});
    const result = NumberUtils.parse(value);
    if (result !== undefined) {
      return formatter.format(result);
    } else {
      return undefined;
    }
  }

}

export class PhoneNumberUtils {

  private static readonly ignoreErrors: string[] = [
    `The string supplied is too long to be a phone number`,
    `The string supplied did not seem to be a phone number`
  ];

  public static isValid(potentialPhoneNumber: string): boolean {
    if (!potentialPhoneNumber) {
      return false;
    }

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(potentialPhoneNumber, 'AU');
      return phoneUtil.isValidNumber(phoneNumber);
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.log(e);
      }
    }

    return false;
  }

  public static format(potentialPhoneNumber: string, mobileOnly?: boolean): string {

    if (!potentialPhoneNumber) {
      return '';
    }

    const input = `${potentialPhoneNumber}`.trim();

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(input, 'AU');

      if (phoneUtil.isValidNumber(parsedPhoneNumber)) {
        if (!mobileOnly) {
          return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL);
        }
        else if (phoneUtil.getNumberType(parsedPhoneNumber) === libphonenumber.PhoneNumberType.MOBILE) {
          return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL);
        }
      }
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.log(e);
      }
    }

    return '';
  }

  public static e164(potentialPhoneNumber: string): string {
    const input = `${potentialPhoneNumber}`.trim();

    if (input === '') {
      return undefined;
    }

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parsedPhoneNumber = phoneUtil.parse(input, 'AU');

      if ( phoneUtil.isValidNumber(parsedPhoneNumber) ) {
        return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.E164);
      }
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.error(e);
      }
    }
  }


  public static parse(potentialPhoneNumber: string): string {
    if (!potentialPhoneNumber) {
      return '';
    }

    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(potentialPhoneNumber, 'AU');
      return phoneUtil.isValidNumber(phoneNumber) ? phoneUtil.format(phoneNumber, libphonenumber.PhoneNumberFormat.E164) :
        potentialPhoneNumber;
    }
    catch (e) {
      return '';
    }
  }
}

export class DateUtils {

  /**
   * Creates a copy of a date object
   */
  public static clone(src: Date): Date|undefined {
    if (!src) {
      return undefined;
    }

    return new Date(src.valueOf());
  }

  public static equals(a: Date, b: Date): boolean {
    if ((!a && !!b) || (!!a && !b)) {
      return false;
    }

    return a.valueOf() === b.valueOf();
  }

  /**
   * Parses a string, and returns a data object truncated to the start of the day.
   * Returns undefined if the date string is not valid.
   */
  public static parse(src: string, includeTime?: boolean): Date|undefined {
    try {
      const d = DateTime.fromISO(src);
      if (d.isValid) {
        return includeTime ? d.toJSDate() : d.startOf('day').toJSDate();
      }
      return undefined;
    }
    catch (e) {
      return undefined;
    }
  }

  /**
   * Converts a Date object to a string.
   * If no format argument is provided, the result is an ISO date string (yyyy-mm-dd)
   */
  public static toString(src: Date, format?: string): string {
    if (!src) {
      return undefined;
    }

    const dateTime = DateTime.fromJSDate(src);
    return format ? dateTime.toFormat(format) : dateTime.toISODate();
  }

  public static toLocaleString(src: Date, formatOpts?: any): string {
  if (!src) {
    return undefined;
  }

  const dateTime = DateTime.fromJSDate(src);
  return formatOpts ? dateTime.toLocaleString(formatOpts) : dateTime.toLocaleString(DateTime.DATE_SHORT);
  }

  /**
   * Converts a Date object to a string, including the time component.
   */
  public static toISOString(src: Date): string {
    if (!src) {
      return undefined;
    }

    const dateTime = DateTime.fromJSDate(src);
    return dateTime.toISO();
  }

  /**
   * Compare dates.
   * https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-diff
   */
  public static diff(d1: Date, d2: Date): number {
    if (!d1 || !d2) {
      return undefined;
    }

    const i1 = DateTime.fromJSDate(d1);
    const i2 = DateTime.fromJSDate(d2);
    return i2.diff(i1).toObject();
  }

}

// tslint:disable-next-line:no-namespace
export namespace UUIDUtils {
  export function isUUID(value: string): boolean {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return re.test(value);
  }
}

export enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Escape = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40,
  Comma = 188
}

export class FloatingPointUtils {

  public static readonly defaultPrecision = 0.01;

  // tslint:disable-next-line:typedef
  private static validatePrecision(precision: number) {
    assert( Math.log10(precision) === Math.round(Math.log10(precision)),  `Precision must be a power of 10` );
  }

  public static equals(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) === Math.round(b / precision);
  }

  public static lessThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) < Math.round(b / precision);
  }

  public static lessThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) <= Math.round(b / precision);
  }

  public static greaterThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    return FloatingPointUtils.lessThan(b, a, precision);
  }

  public static greaterThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    return FloatingPointUtils.lessThanOrEqualTo(b, a, precision);
  }
}

export class TextUtils {

  public static stringFormat(template: string, ...args: any[]): string {
    return template.replace(/{(\d+)}/g, (match, d: any) => {
      return typeof args[d] !== 'undefined'
        ? args[d]
        : match
        ;
    });
  }

  public static isBlank(value: string | null): boolean {
    return !!value;
  }

  public static capitalize(input: string): string {
    const arr = input.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  }

}

export class EqualityUtils {

  static equals(lhs: any, rhs: any): boolean {
    if (lhs instanceof Array) {
      return (rhs instanceof Array) && (rhs.length === lhs.length) && lhs.every((v, i) => EqualityUtils.equals(v, rhs[i]));
    }
    if (lhs && lhs.hasOwnProperty('value')) {
      return (rhs && rhs.hasOwnProperty('value')) && EqualityUtils.equals(lhs['value'], rhs['value']);
    }
    return lhs === rhs;
  }

}
