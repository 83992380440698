import { NgModule, Injector, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AmplifyService } from 'aws-amplify-angular';
import { HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '@modules/shared/shared.module';

import { ServiceLocator } from '@classes/serviceLocator';
import { AppComponent } from '@components/app/root/app.component';
import { AppHeaderComponent } from '@components/app/header/app-header.component';
import { NotLoggedInMenuComponent } from '@components/app/menu/defaultMenu.component';
import { DefaultComponent } from '@components/app/default/default.component';
import { AccessDeniedComponent } from '@components/accessdenied/denied.component';
import { LoginComponent } from '@components/login/login.component';
import { ModalComponent, HorizontalResize } from '@components/modal/modal.component';
import { NotificationComponent } from '@components/notifications/notifications.component';
import { MaintenanceComponent } from '@components/maintenance/maintenance.component';
import { MaintenanceBannerComponent } from '@components/maintenance/banner/banner.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { initIconLibrary } from './icons';
import { AppRoutingModule } from './app-routing.module';
import { WebSocketService } from '@services/websocket.service';
import { SessionStorageService } from '@services/storage.service';
import { Settings } from 'luxon';

@NgModule({
	"declarations": [
		AppComponent,
		AppHeaderComponent,
		NotLoggedInMenuComponent,
		DefaultComponent,
		ModalComponent,
		NotificationComponent,
		LoginComponent,
		AccessDeniedComponent,
		MaintenanceComponent,
		MaintenanceBannerComponent,
		HorizontalResize
	],
	"imports": [
		BrowserModule,
		// ReactiveFormsModule,
		FormsModule,
		SharedModule,
		FontAwesomeModule,
		AppRoutingModule,
		HttpClientModule
	],
	"providers": [{
			"provide": LOCALE_ID, 'useValue': 'en-AU'
		},
		AmplifyService,
		DecimalPipe,
		WebSocketService,
	],
	"exports": [],
	"bootstrap": [AppComponent]
})
export class AppModule {

	constructor(private injector: Injector, library: FaIconLibrary) {

		ServiceLocator.injector = this.injector;
		initIconLibrary(library);

		// Set default locale on Luxon objects
		Settings.defaultLocale = 'en-AU';

		// Save the requested URL, so that (for example) opening a link in a new window works correctly.
		SessionStorageService.set('path', window.location.pathname);
	}
}
